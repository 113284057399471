.header_main_container {
  height: 55px !important;
  overflow: hidden !important;
  background: #f9f8f8;
}
.Mewahtrans_GPS_heading {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 15px !important;
  color: #000000 !important;
}
.header_time {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 12px !important;
  color: #000000 !important;
  margin-left: 10px !important;
  margin-top: 3px !important;
}
.header_links {
  margin-right: 30px !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  color: #070707 !important;
  text-transform: capitalize !important;
  transition: 0.2s ease-in-out !important;
  cursor: pointer !important;
}
.header_links_smallScreen {
  margin-left: 30px !important;
}
.active {
  color: #00a3ff !important;
}
.header_links:hover {
  color: #00a3ff !important;
  transition: 0.2s ease-in-out !important;
}
.header_links_icons {
  margin-right: 10px !important;
  font-size: 20px !important;
}
.onSmallScreen {
  display: none !important;
}
.onLargeScreen {
  display: flex !important;
}
.menu_icon {
  margin-right: 20px !important;
}
@media screen and (max-width: 600px) {
  .onSmallScreen {
    display: flex !important;
  }
  .onLargeScreen {
    display: none !important;
  }
}
