.sidebar_search {
  background: #f7f7f7 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  height: 30px !important;
  margin: 4px 0px !important;
  padding: 2px 18px !important;
}
.sidebar_search_input {
  background: #f7f7f7 !important;
  outline: none !important;
  border: none !important;
  width: 100% !important;
  padding: 0px 12px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #a09d9d !important;
}
.sidebar_location_name {
  font-size: 16px !important  ;
}
.sidebar_location_dropdown {
  background: #197ec1 !important;
  padding: 8px 16px !important;
  color: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer !important;
  height: 29px !important;
}
.sidebar_location_text {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
}
.sidebar_location_icon {
  margin-right: 6px !important;
}
.sidebar_allTrack {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 15px !important;
}
.all_tracks_status {
  padding: 15px 16px !important;
  background: #475c68 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: white !important;
  cursor: pointer !important;
}
.sidebar_box_icon {
  width: 12px !important;
  height: 12px !important;
  background: #d9d9d9 !important;
  margin-right: 6px !important;
  border: none !important;
  outline: none !important;
}
.tracks_Status {
  margin-right: 40px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 15px !important;
}
.single_track_box {
  /* background: #fefefe !important; */
  border-radius: 5px !important;
  margin: 4px 0px !important;
}
.single_track {
  padding: 5px 0px 5px 16px !important;
  width: 100% !important;
  vertical-align: middle !important;
}
.Stop_icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px !important;
  background-color: red !important;
  border-radius: 100px !important;
}
.Stop_icon_square {
  width: 8px !important;
  height: 8px !important;
  background: white !important;
}
.traveling_icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px !important;
  background-color: green !important;
  border-radius: 100px !important;
}
.sleep_icon {
  margin-right: 8px !important;
}
.trach_current_status {
  margin-right: 14px !important;
  width: 55px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 15px !important;
}
.single_track_text {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  vertical-align: middle !important;
}
.Chip {
  border: 2px solid #fefefe !important;
  border-radius: 100px !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 8px !important;
  padding: 10px 0px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  cursor: pointer !important;
}
.Accordion {
  width: 100% !important;
}
.border_radius {
  border-radius: 5px !important;
  overflow: hidden !important;
  margin-bottom: 5px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding-left: 10px !important;
}
.icon {
  margin-right: 3px !important;
}
.vertical-place {
  display: flex;
  align-items: center;
  justify-content: center;
}