.setting_tabs_container {
  padding: 20px 10px !important;
}
.setting_tab_active,
.setting_tab {
  background-color: #d9d9d9 !important;
  width: 100% !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  color: #ffffff !important;
  margin: 10px 0 !important;
  text-transform: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.setting_tab_active {
  background-color: #197ec1 !important;
}
@media screen and (max-width: 599px) {
}
