.SoundSystem_container {
  padding: 18px !important;
}
.line {
  background-color: #c4c4c4 !important;
  height: 1px !important;
}
.SoundSystem_headings {
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #000000 !important;
  margin: 10px 0px !important;
}
.aaa {
  padding: 0px 10vw !important;
}

/* ///////////////// */
.toggle {
  --width: 85px;
  --height: calc(var(--width) / 3);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 36px;
  border-radius: 13px;
  background-color: #9ea199;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
  margin-left: -3px;
}

.toggle input:checked + .slider {
  background-color: #197ec1;
}

.toggle input:checked + .slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: white;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
  transform: translate(-3px, 4px);
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: white;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  transform: translate(16px, 4px);
}

.toggle input:checked ~ .labels::after {
  opacity: 0;
}

.toggle input:checked ~ .labels::before {
  opacity: 1;
}
.SoundSystem_toggle_btn_box {
  height: 50px !important;
  margin: 10px 0px !important;
}
.tool_box {
  position: absolute !important;
  right: 0 !important;
  background-color: white !important;
  max-height: 350px !important;
  overflow: scroll;
}
.tool_box::-webkit-scrollbar {
  display: none !important;
}
.toolBox_container::-webkit-scrollbar {
  display: none !important;
}
.single_tool_active,
.single_tool {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer !important;
  transition: 0.1s ease-in-out !important;
  border-bottom: 1px solid black !important;
  font-size: 14px !important;
}
.single_tool_active {
  background-color: #198dae !important;
  color: white !important;
}
.single_tool:nth-child(odd) {
  background-color: #e1e1e1 !important;
}
.single_tool:hover {
  background-color: #198dae !important;
  color: white !important;
  transition: 0.1s ease-in-out !important;
}
#mapText {
  position: absolute;
  bottom: 10%;
  font-size: 18px;
  color: red;
  background: #e1e1e1;
}
/* Increase the size of the DrawingManager menu */
.gm-style .gm-style-mtc label,
.gm-style .gm-style-mtc div {
  font-size: 50px !important; /* Change this to your desired font size */
}
.rest-container-drawing {
  height: calc(100vh - 154px) !important;
  max-height: calc(100vh - 154px) !important;
}
.text-input .MuiInputBase-root {
  height: 40px !important;
  padding: 0px 10px !important;
}
.accordion-open {
  margin: 5px 0 !important;
}
.checkbox_inFlex {
  text-align: left !important;
  justify-content: flex-start !important;
  margin-right: 15px !important;
}
.copyright-layout {
  margin-top: 10px !important;
}