/* Add this in your style.css file */
.vertical-progress-with-scale {
  display: flex;
  align-items: center;
  height: 100%; /* Adjust as needed */
}

.scale {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.scale span {
  position: relative;
  width: 20px; /* Width of the scale */
  text-align: right;
  margin-right: 13px !important;
  font-size: 10px !important;
}

.scale span::after {
  content: "";
  position: absolute;
  right: 6 !important;
  top: 50%;
  transform: translateY(-50%);
  width: 5px; /* Length of the ruler tick */
  height: 1px; /* Thickness of the ruler tick */
  background-color: black;
  margin-left: 5px !important;
}

.vertical-progress-with-scale .rs-progress, .rs-progress-icon-fail, .rs-progress-icon-success, .rs-progress-info-status {
  font-size: 12px !important;
}

.vertical-progress-with-scale .rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner {
  width: 15px !important;
}

.vertical-progress-line-content {
  margin-bottom: 20px !important;
}