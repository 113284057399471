.home_drawer_btn {
  display: none !important;
}
.home_sidebar_box {
  max-height: 90vh !important;
  overflow: scroll !important;
}
.home_sidebar_box::-webkit-scrollbar {
  display: none !important;
}
.close_drawer_btn {
  padding: 5px 15px !important;
}
.home_page_container {
  background-color: white;
  width: 100% !important;
  height: 100% !important;
}
.location_page_left_container_withOutPadding,
.location_page_left_container {
  background-color: #eeeded !important;
  padding: 15px !important;
  overflow: scroll !important;
  max-height: calc(100vh - 55px) !important;
}
.location_page_left_container_withOutPadding::-webkit-scrollbar,
.location_page_left_container::-webkit-scrollbar {
  display: none;
}
.location_page_left_container_withOutPadding {
  padding: 0px !important;
  height: calc(100vh - 55px) !important;
}
/* @media screen and (max-width: 780px) {
  .home_sidebar_box {
    display: none !important;
    height:100vh;
  }
  .home_drawer_btn {
    display: flex !important;
    margin: 10px !important;
  }
} */
#marker-map + button {
  display: none !important;
}
button.gm-ui-hover-effect {
  visibility: hidden;
}
div.gm-style-iw-d {
  overflow: hidden !important;
}