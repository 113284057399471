* {
  margin: 0 !important;
  padding: 0 !important;
}
body {
  background: #f9f8f8 !important;
}
/* cursor pointer */
.pointer {
  cursor: pointer !important;
}
/* display */
.df {
  display: flex !important;
}
.dg {
  display: grid !important;
}
/* justify_content */
.jc {
  justify-content: center !important;
}
.jsb {
  justify-content: space-between !important;
}
.jsa {
  justify-content: space-around !important;
}
.je {
  justify-content: end !important;
}
/* align_items */
.ac {
  align-items: center !important;
}

body::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0); /* color of the tracking area */
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: #198dae; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 0px solid rgba(0, 0, 0, 0); /* creates padding around scroll thumb */
}
.notistack-MuiContent {
  /* padding: 13px !important; */
}
.notistack-CollapseWrapper {
  margin-bottom: 10px !important;
}
#notistack-snackbar svg {
  margin-inline-end: 10px !important;
}
#notistack-snackbar {
  margin-right: 10px !important;
  padding: 13px !important;
}
.go703367398 button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 35px !important;
  padding-top: 3px !important;
  margin-right: 13px !important;
}
.go703367398 button span{
  margin-top: 2px !important;
}
