#iw-container {
	margin-bottom: 10px;
	width: 400px !important;
}
#iw-container .iw-title {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 15px;
	font-weight: 400;
	padding: 10px;
	background-color: #475c68;
	color: white;
	margin: 0;
	border-radius: 2px 2px 0 0;
	width: 100%;
	padding-top: 15px !important;
	padding-left: 15px !important;
	padding-bottom: 15px !important;
}
#iw-container .iw-content {
	font-size: 13px;
	line-height: 18px;
	font-weight: 400;
	margin-right: 1px;
	padding: 15px !important;
	max-height: 120px;
	overflow-y: auto;
	overflow-x: hidden;
}
.iw-content img {
	float: right;
	margin: 0 5px 5px 10px;	
}
.iw-subTitle {
	font-size: 16px;
	font-weight: 700;
	padding: 5px 0;
}
.iw-bottom-gradient {
	position: absolute;
	width: 326px;
	height: 25px;
	bottom: 10px;
	right: 18px;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.circle {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  font-size: 13px;
	font-weight: bold;
  color: #000;
  text-align: center;
	float: right;
}
.icon-style {
	vertical-align: middle;
}
.gm-style-iw-chr {
	overflow: hidden !important;
	display: none !important; /* hidden empty space a top */
}