.login-container {
  padding: 0 24px !important;
}
.signin-text {
  margin: 10px 0 !important;
}
.textfield-spacing {
  margin-top: 6px !important;
}
.textfield-height {
  height: 66px !important;
}
.button-spacing {
  margin-top: 13px !important;
}
.copyright-spacing {
  margin-top: 50px !important;
}
.submit-btn {
  height: 35px !important;
}
.login-image {
  background-image: url("../../../@assets/images/mewahtrans_truck.jpg") !important;
}