.report_page_container {
  padding: 10px 58px !important;
}
.Report_Check_heading {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  text-decoration-line: underline !important;
  color: #000000 !important;
  margin-top: 20px !important;
}
.report_type_heading {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  color: #000000 !important;
  margin-top: 23px !important;
}
.report_type_input_box {
  background: #fefefe !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  height: 35px !important;
  width: 100% !important;
  margin-top: 15px !important;
  font-size: 18px !important;
}
.report_type_input {
  outline: none !important;
  width: 97% !important;
  border: none !important;
  font-size: 18px !important;
}
.filter_by_date_input_box,
.date_time_input_box {
  background: #fefefe !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  height: 29px !important;
  margin-top: 7px !important;
  outline: none !important;
  width: 100% !important;
}
.filter_by_date_input_box {
  margin-top: 0px !important;
}
.date_time_input {
  outline: none !important;
  width: 100% !important;
  border: none !important;
  margin-left: 12px !important;
  font-size: 18px !important;
}
.calendar {
  margin-right: 12px !important;
}
.filter_by_search_btn,
.report_search_btn {
  width: 200px !important;
  height: 50px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  color: white !important;
  text-transform: capitalize !important;
  background: #197ec1 !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  margin-top: 21px !important;
  margin-bottom: 10px !important;
}
.filter_by_search_btn {
  width: 100% !important;
  height: 35px !important;
  border-radius: 10px !important;
  /* margin-top: 0px !important; */
  vertical-align: middle;
}
.on_time_kpi_container {
  background: #fefefe !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  margin-top: 15px !important;
}
.on_time_kpi_grid1 {
  padding: 10px 15px !important;
  border-bottom: 1px solid #d2d2d2 !important;
}
.see_details_btn {
  background: #c4c4c4 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  color: black !important;
  text-transform: capitalize !important;
  width: 67.2px !important;
  height: 18px !important;
  padding-top: 2px !important;
}
.on_time_kpi_grid2 {
  padding: 10px 15px !important;
}
@media screen and (max-width: 599px) {
  .report_page_container {
    padding: 20px 30px !important;
  }
  .mt_10px {
    margin-top: 10px !important;
  }
}
.selector-layout {
  margin-top: 9px !important;
}
.divider-line {
  margin-top: 18px !important;
}
