/* .css-idzn43 {
  width: 100%;
  height: 35px;
  padding-left: 9px !important;
  margin-top: 9px !important;
} */
/* .css-nkhozk {
  width: 50%;
  height: 35px;
} */
#no-title {
  height: 35px !important;
  display: flex;
  align-items: center;
  padding: 0 18px !important;
}
