body #fuse-splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111827;
  color: #F9FAFB;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

body #fuse-splash-screen img {
  width: 120px;
  max-width: 120px;
}

#spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 56px;
}

#spinner > div {
  width: 12px;
  height: 12px;
  background-color: #1E96F7;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
  animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

#spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

#spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}